<template>
    <div class="container">

        

    </div>
    
</template>

<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {

        }
    },
    async mounted(){
        await this.getEnvVarConf()
    },
    methods:{
        
        initialize(){
            

        },

        async getEnvVarConf(){
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=GBRK&appl_id=WEBGBRK`, null, false, false, false)
            if (respData.status === 200) {
                this.$store.commit('SET_GROUP_GBRK', respData.data.data)
            }
        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>


</style>